<template>
<div>
    <pageTitle :title="page_lable" class="fff"></pageTitle>
    <div class="line"></div>
    <el-form :model="roleForm" v-loading='loading' :rules="rules" ref="ruleForm"  :label-position="'left'" label-width="120px" class="formBox fff" >
        <!-- 频道名称 -->
        <el-form-item :label="$t('ChannelName')" prop='channel_name' >
            <el-input v-model="roleForm.channel_name" autocomplete="off"  size="small" style="width:280px" maxlength="32" :placeholder="$t('fill32')" show-word-limit></el-input>
        </el-form-item>
        <el-form-item :label="$t('Liveicon')" >
            <div class="imgBox">
                <div>
                    <img v-if="PJSource === 10" :src="roleForm.player_icon_url" alt="" style="width: 140px;height:140px">
                    <img v-if="PJSource ===21&&player_icon_url_change" :src="roleForm.player_icon_url" alt="" style="width: 140px;height:140px">
                    <img v-if="PJSource === 11" :src="roleForm.fudaPlayer_icon_url" alt="" style="width: 140px;height:140px">
                    <span class="label" >{{$t('maxSize2M')}}</span>
                </div>
                <div>
                    <fileUpload
                        @uploadCompleteOSS="_uploadCompleteOSS_logo"
                        :accept="fileUploadOptions.accept"
                        :needCropImg="false"
                        aspectRatio='140/140'
                        :limitSize='2'
                        :autoUpload="fileUploadOptions.autoUpload"
                    >
                        <el-button type="primary" class="up_btn" size="small">{{$t('Upload icon')}}</el-button>
                    </fileUpload>
                </div>
                
            </div>
        </el-form-item>
        <!-- 引导页图片： -->
        <el-form-item :label="$t('Guide page image')">
            <div class="imgBox">
                <div>
                    <img v-if="PJSource === 10"  :src="roleForm.guide_img_url" alt="" style="width: 140px;height: 249px;">
                    <img v-if="PJSource === 11"  :src="roleForm.fudaguide_img_url" alt="" style="width: 140px;height: 249px;">
                    <img v-if="PJSource === 21 && guide_img_url_change"  :src="roleForm.guide_img_url" alt="" style="width: 140px;height: 249px;">
                    <span class="label">{{$t('size750_1334')}}</span>
                </div>
                
                <div>
                    <fileUpload
                            @uploadCompleteOSS="_uploadCompleteOSS_main"
                            :accept="fileUploadOptions.accept"
                            :needCropImg="false"
                            aspectRatio='750/1334'
                            :limitSize='2'
                            :autoUpload="fileUploadOptions.autoUpload"
                        >
                            <el-button  type="primary" class="up_btn"  size="small">{{$t('Replacepic')}}</el-button>
                    </fileUpload>
                </div>
            </div>
        </el-form-item>
        <!-- 自定义PC观看页背景图 -->
        <!-- <el-form-item :label="$t('Customize_bg_page')">
            <div class="imgBox">
                <div>
                    <img :src="roleForm.guide_img_mobile_url" style="width: 300px;height: 169px;" alt="">
                    <span class="label">{{$t('size1600*900')}}</span>
                </div>
                
                <div>
                    <fileUpload
                        @uploadCompleteOSS="_uploadCompleteOSS_self"
                        :accept="fileUploadOptions.accept"
                        :needCropImg="false"
                        aspectRatio='160/90'
                        :limitSize='2'
                        :autoUpload="fileUploadOptions.autoUpload"
                    >
                        <el-button   type="primary" class="up_btn" size="small">{{$t('RbackgroundPic')}}</el-button>
                    </fileUpload>
                    
                </div>
            </div>
        </el-form-item> -->
        <el-form-item :label="$t('Live_introduction')">
           <el-input
                :autosize="{ minRows: 10, maxRows: 10 }"
                maxlength="900"
                style="height: 220px;width:1000px"
                show-word-limit
                type="textarea"
                :rows="2"
                :placeholder="$t('Live_introduction')"
                v-model="roleForm.introduction">
            </el-input>

        </el-form-item>
        <el-form-item>
            <el-button type="primary" size="small" @click="handleUpload('ruleForm')">{{$t('save')}}</el-button>
        </el-form-item>
    </el-form>
</div>
</template>
<script>
import pageTitle from "~bac/components/common/pageTitle";
import fileUpload from "@/baseComponents/fileUpload";
export default {
    name:"conference-attendance",
    components:{
        pageTitle,fileUpload
    },
    data(){
        return{
            player_icon_url_change:false,
            guide_img_url_change:false,
            title:"",
            loading:false,
            defaultImg:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/head.png',
            fileUploadOptions: {
                accept: ".jpg,.jpeg,.png",
                autoUpload:true
            },
            roleForm:{
                booth_id:"",
                channel_name:"",
                guide_img_url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/live_yindao.jpg",//引导图移动端地址   
                fudaguide_img_url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/fdApplent/lADPD2eDPcpWXwnNBTbNAu4_750_1334.jpg",
                player_icon_url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/live_icon.jpg",//播放器图标地址
                fudaPlayer_icon_url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/fdApplent/lADPD4BhtEvAeYTMjMyM_140_140.jpg",
                introduction:"",//直播介绍
                guide_img_mobile_url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/live_bg.jpg",//背景图
                channel_id:"",//频道修改
            },
            
            page_lable:"",
            rules:{
                channel_name:[
                     { required: true, message: this.$t('fill32'), trigger: 'blur' }
                ],
            }
        }
    },
    mounted(){
        this.page_lable=this.$t('Livesettings')
        if(this.LOCALE=='en'){
            this.roleForm.guide_img_url="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/live_yindao_en.jpg"
        }
     
        this.roleForm.meeting_id=this.MEETING_ID
        this.roleForm.company_id=this.$store.state.baseStore.userInfo.company_id
        if(this.$route.query.type=='add'){

        }else{

            // 初始化赋值
            let p=JSON.parse(localStorage.liveParams)
            this.roleForm.live_id=p.id
            this.roleForm.channel_id=p.channel_id

            this.roleForm.channel_id=p.channel_id
            this.roleForm.booth_id=""
            this.roleForm.channel_name=p.channel_name
            this.roleForm.guide_img_url=p.guide_img_url//引导图地址 
            this.roleForm.player_icon_url=p.player_icon_url//播放器图标地址
            this.roleForm.guide_img_mobile_url=p.guide_img_mobile_url//引导图移动端地址
            this.roleForm.introduction=p.introduction//介绍


        }
    },
    beforeDestroy(){
        localStorage.liveParams=null
    },
    methods:{
       
        // quxiao
        back(){
            window.history.go(-1)
        },
        // 上传图片--logo
        _uploadCompleteOSS_logo(res){
            this.player_icon_url_change=true
            this.roleForm.player_icon_url = res.url;
        },
        // 上传图片--引导页图片
        _uploadCompleteOSS_main(res){
            this.guide_img_url_change=true
            this.roleForm.guide_img_url = res.url;
        },
        // 上传图片--自定义PC观看页背景
        _uploadCompleteOSS_self(res){
            this.roleForm.guide_img_mobile_url = res.url;
        },
        //提交
        handleUpload(formName){
            if(!this.roleForm.channel_name){
                this.$message.warning(this.$t('fill32'))
            }
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.loading=true
                    let params=this.roleForm
                    params.guide_enable="Y"
                    let msg=""
                    if(this.$route.query.type=='add'){
                        msg=this.$t('Createdsuccessfully')
                    }else{
                        msg=this.$t('SetSuccess')
                    }
                    let res = await this.$store.dispatch('baseConsole/createLive', params)
                    this.loading=false
                    if(res && res.success){
                        this.$message.success(msg)
                        this.back()   
                    }
                    if(res && res.message=='not booth'){
                        this.$message.warning(this.$t('Failedtocreate'))
                    }
                } else {
                    return false;
                }
            });




            
        }
    }
}
</script>
<style scoped lang="less">
    .fff{
         background:#fff
    }
    .formBox{
        padding-top: 20px;
        padding-left: 20px;
        padding-bottom: 30px;
    }   
    .line{
        width: 100%;
        height: 1px;
        background: #C0C4CC;
    }
    .imgBox{
        .label{
            display: inline-block;
            padding-left: 12px;
            width: 277px;
            height: 34px;
            font-size: 12px;
            font-weight: 400;
            color: #909399;
            line-height: 17px;
        }
        .up_btn{
            margin-top: 12px;
            width: 140px;
        }
    }
  
    /deep/ .formBox .el-form-item {
        margin-bottom: 22px !important;
    }

</style>